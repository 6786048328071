import { render, staticRenderFns } from "./topBannar.vue?vue&type=template&id=7aa8d80a&scoped=true"
import script from "./topBannar.vue?vue&type=script&lang=js"
export * from "./topBannar.vue?vue&type=script&lang=js"
import style0 from "./topBannar.vue?vue&type=style&index=0&id=7aa8d80a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa8d80a",
  null
  
)

export default component.exports