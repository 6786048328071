<!--
 * @Author: lbh
 * @Date: 2022-06-15 12:02:59
 * @LastEditors: lbh
 * @LastEditTime: 2023-11-14 16:04:13
 * @Description: file content
-->
<template>
  <div
    ref="topBannarBox"
    :data-tab-color="configs.tabColor"
    class="self-top_bannar-box px-top_bannar-box"
  >
    <el-image
      class="image-box"
      :class="`edit_${Xindex}_src`"
      @click="clickItem('src')"
      :src="configs.src"
      :alt="configs.alt"
    ></el-image>
    <div class="title-box bounceInLeft animated">
      <div class="top-t">
        <h1
          class="title"
          :class="`edit_${Xindex}_title`"
          @click="clickItem('title')"
          v-html="$util.rex.getHtml(isEdit ? (configs[`${nowL}title`] || configs.title || '點我輸入標題') : configs[`${nowL}title`] || configs.title)"
        ></h1>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: "topBannar",
  mixins: [langMixin],
  data () {
    return {
      isEdit: false
    }
  },
  props: {
    configs: {
      default () {
        return {
          src: "",
          title: ""
        }
      }
    },
    Xindex: {
      default () {
        return '';
      }
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  mounted () {
    let topBannarBox = this.$refs.topBannarBox;
    let color = topBannarBox.dataset.tabColor;
    if (document.querySelector('.header-box'))
      document.querySelector('.header-box').style.cssText = `--menuColor:${color}`;
  },
  methods: {
    clickItem (e) {
      if (this.isEdit) {
        this.$emit('clickItem', e);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.self-top_bannar-box {
  position: relative;
  .image-box {
    vertical-align: middle;
  }
  .title-box {
    // transform: translate3d(0, calc(-50% - 10px), 0) !important;
    position: absolute;
    z-index: 0;
    // top: calc(50% - 40px);
    top: 160px;
    left: 360px;
    .title {
      font-size: 42px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .line {
      height: 6px;
      width: 60px;
      background-color: #fff;
      margin-top: 36px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .px-top_bannar-box {
    height: calc(100vw / 3.4);
    .image-box {
      height: 100%;
      & /deep/ .el-image__inner {
        object-fit: cover;
      }
    }
    .title-box {
      top: 50%;
      left: 71px;
      .top-t {
        transform: translateY(-50%);
      }
      .title {
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .px-top_bannar-box {
    height: calc(100vw / 3.4);
    .image-box {
      height: 100%;
      & /deep/ .el-image__inner {
        object-fit: cover;
      }
    }
    .title-box {
      top: 50%;
      left: 30px;
      .title {
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
</style>